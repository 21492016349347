import { API, AdultAPI, removeBlankAttributes } from "@project/shared"
export type CompanySearchQuery = {
  page?: number
  pageSize?: number
  CompanyName?: string
  RepresentativeName?: string
}
type Zip = {
  zip_code?: string
  company_id?: number
  perf_name?: string
  city_name?: string
  town_name?: string
}
export type CompanyResponseObj = {
  Facilities?: any
  FaxNo_ShowMyPage?: boolean
  Pref?: any
  TelNo_ShowMyPage?: boolean
  Zip: Zip
  account_status?: string
  address?: string
  address2?: string
  company_name?: string
  created_by?: string
  created_datetime?: string
  day_service_name?: string
  deleted_by?: string
  deleted_datetime?: any
  fax_no?: string
  id?: number
  is_deleted?: boolean
  last_logged_in?: string
  mail_address?: string
  memo?: string
  position_name?: string
  pref_id?: number
  reception_info?: string
  representative_director_name?: string
  tel_no?: string
  updated_by?: string
  updated_datetime?: string
  zip_code?: string
}

export type CompanyResponseData = {
  data: CompanyResponseObj[]
  count: number
}

export const getAllCompanies = async (
  queries: CompanySearchQuery
): Promise<CompanyResponseData> => {
  const searchQueries: any = { ...queries }
  searchQueries.page = 1
  searchQueries.pageSize = "Infinity"
  const params = removeBlankAttributes(searchQueries)
  return API.get(`/companies`, { params })
}
// adding comments to trigger deployment

export const getAllAdultCompanies = async (
  queries: CompanySearchQuery
): Promise<CompanyResponseData> => {
  const searchQueries: any = { ...queries }
  searchQueries.page = 1
  searchQueries.pageSize = "Infinity"
  const params = removeBlankAttributes(searchQueries)
  return AdultAPI.get(`/companies`, { params })
}

type SingleCompanyRes = {
  account_status?: string
  company_type?: string
  address?: string
  company_name?: string
  email?: string
  fax_number?: string
  memo?: string
  password?: string
  phone_number?: string
  po_box_no?: string
  reception_hours?: string
  representative?: string
}
export const getCompany = async (
  id: number
): Promise<{ data: SingleCompanyRes }> => API.get(`/companies/${id}`)

export const getAdultCompany = async (
  id: number
): Promise<{ data: SingleCompanyRes }> => AdultAPI.get(`/companies/${id}`)

export const createNewCompany = async (values: any) =>
  API.post(`/companies/create`, { ...values })

export const createNewAdultCompany = async (values: any) =>
  AdultAPI.post(`/companies/create`, { ...values })

export const updateCompany = async ({ id, ...rest }: any) =>
  API.put(`/companies/${id}`, { ...rest })

export const updateAdultCompany = async ({ id, ...rest }: any) =>
  AdultAPI.put(`/companies/${id}`, { ...rest })
