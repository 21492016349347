import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons"
import { notification, Skeleton } from "antd"
import { useFormik } from "formik"
import { t } from "i18next"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import * as yup from "yup"

//shared component imports
import {
  BreadCrumb,
  Button,
  dynamicLangString,
  PageCard,
  RadioButtons,
  Required,
  Grid as SharedGrid,
  TextField,
} from "@project/shared"
import { scrollToFirstErrorField } from "../../../utils"
//services imports
import {
  createNewAdultCompany,
  createNewCompany,
  getAdultCompany,
  getCompany,
  updateAdultCompany,
  updateCompany,
} from "../../../services/company"

const Grid = styled(SharedGrid)`
  &.grid-gap {
    margin-top: 100px;
    border-bottom: 1px solid #cacbcd;
  }
  @media (max-width: 987px) {
    &.grid-gap {
      margin-top: 0px;
      border-bottom: 0px;
    }
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  .inner-grid-content {
    width: 100%;
  }
  .grid-header {
    width: 300px;
    padding: 28px 55px 28px 18px;
  }
  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  input,
  textarea {
    background-color: #ffffff;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 1;
    .grid-header {
      width: 100%;
      padding: 10px;
    }
  }
  .text-required {
    color: #e00;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 7px;
  }
  .text-link {
    color: #0782c8;
    text-decoration: underline;
    display: inline-flex;
    margin-right: 15px;
  }
  .text-field-headertext {
    margin-top: 20px;
  }
  .button-wrapper {
    margin-top: 30px;
    button {
      :first-child {
        margin-right: 10px;
      }
    }
  }
  .ant-radio.ant-radio-checked .ant-radio-inner::after {
    background-color: #0782c8;
  }
  .has-error-container {
    border: 1px solid #e00000;
  }
  .has-error-container-nest > div {
    border: 1px solid #e00000 !important;
  }
  .has-error input,
  .has-error select,
  .has-error textarea {
    border: 1px solid #e00000;
  }
  .password-info-text {
    font-size: 12px;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
  }

  & small {
    display: block;
    text-align: right;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
  .register-button {
    @media (max-width: 430px) {
      margin-top: 10px;
    }
  }
`

const status_options = [
  {
    label: t("Using"),
    value: "active",
  },
  {
    label: t("Suspended"),
    value: "inactive",
  },
]
const service_options = [
  {
    label: t("Child"),
    value: "C",
  },
  {
    label: t("Adult"),
    value: "A",
  },
]
interface Props {
  id?: number
  company_type?: string
}
const CompanyForm: React.FC<any> = (props: Props) => {
  const { id, company_type } = props
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
  const router = useRouter()

  const breadCrumbItems = [
    {
      key: "facility",
      name: t("Company list"),
      href: "/company",
    },
    { key: "add-form", name: t("Add form") },
  ]
  const initialValues = {
    company_name: "",
    company_type: "C",
    representative: "",
    po_box_no: "",
    address: "",
    phone_number: "",
    fax_number: "",
    reception_hours: "",
    email: "",
    password: "",
    memo: "",
    account_status: "active",
  }

  const passwordValidator = id
    ? yup.string()
    : yup
        .string()
        .required(t("Required"))
        .min(6, t("Password must be at least 6 characters"))
        .max(16)

  const PHONE_NO_REGEX = /^(?=.*[0-9])[-0-9]+$/

  const validationSchema = yup.object().shape({
    company_name: yup.string().required(t("Required")).max(100),
    representative: yup.string().required(t("Required")).max(20),
    po_box_no: yup
      .string()
      .required(t("Required"))
      .max(10, t("(Within 10 char)"))
      // TODO: DBの定義と不一致
      .matches(/([0-9]{3}[0-9]{4})/, t("Invalid PostBox")),
    address: yup.string().required(t("Required")).max(100),
    phone_number: yup
      .string()
      .max(100)
      .matches(PHONE_NO_REGEX, {
        message: t(
          "(Please enter within 100 characters with numbers & hyphens only)"
        ),
        excludeEmptyString: true,
      })
      .required(t("Required")),
    fax_number: yup
      .string()
      .required(t("Required"))
      .max(
        100,
        t("(Please enter within 100 characters with numbers & hyphens only)")
      ),
    reception_hours: yup
      .string()
      .required(t("Required"))
      .max(100, t("(Within 100 char)")),
    email: yup
      .string()
      .required(t("Required"))
      .email(t("Email address format is incorrect"))
      .max(100, t("(Within 100 char)")),
    password: passwordValidator,
    memo: yup.string().max(500, t("(Within 500 char)")),
    account_status: yup.string().required(t("Required")),
    company_type: yup.string().required(t("Required")),
  })
  //create notification mutation
  const { mutate: editCompany, isLoading: isUpdating } = useMutation(
    company_type == "C" ? updateCompany : updateAdultCompany,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Company information",
            "Updated Successfully",
          ]),
        })
        router.back()
      },
      onError: (error: any) => {
        const msg = error?.data?.error?.error
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator."),
        })
      },
    }
  )
  //update company mutation
  const { mutate: createCompany, isLoading: isCreating } = useMutation(
    createNewCompany,
    {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Company information",
            "Created Successfully",
          ]),
        })
        router.push("/company")
      },
      onError: ({ data }) => {
        const message = [
          "Error Creating Company: Email already exists",
          "User with email already exists: Child with login id already Exists",
        ].includes(data?.error?.error)
          ? ["Error Creating Company: Email already exists"]
          : ["Something went wrong. Please contact administrator"]
        notification.error({
          message: dynamicLangString(message),
        })
      },
    }
  )

  const { mutate: createAdultCompany, isLoading: isCreatingAdultCompany } =
    useMutation(createNewAdultCompany, {
      onSuccess: () => {
        notification.success({
          message: dynamicLangString([
            "Company information",
            "Created Successfully",
          ]),
        })
        router.push("/company")
      },
      onError: ({ data }) => {
        const message = [
          "Error Creating Company: Email already exists",
          "User with email already exists: Child with login id already Exists",
        ].includes(data?.error?.error)
          ? ["Error Creating Company: Email already exists"]
          : ["Something went wrong. Please contact administrator"]
        notification.error({
          message: dynamicLangString(message),
        })
      },
    })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (id) {
        editCompany({ id, ...values })
      } else {
        if (values.company_type == "C") createCompany(values)
        else if (values.company_type == "A") createAdultCompany(values)
      }
    },
  })
  const { isLoading, isFetching } = useQuery({
    queryKey: ["notification", id],
    queryFn: () => (company_type == "C" ? getCompany(id) : getAdultCompany(id)),
    enabled: !!id,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      formik.setFieldValue("company_name", res?.data?.company_name)
      formik.setFieldValue("po_box_no", res?.data?.po_box_no)
      formik.setFieldValue("address", res?.data?.address)
      formik.setFieldValue("fax_number", res?.data?.fax_number)
      formik.setFieldValue("phone_number", res?.data?.phone_number)
      formik.setFieldValue("representative", res?.data?.representative)
      formik.setFieldValue("reception_hours", res?.data?.reception_hours)
      formik.setFieldValue("email", res?.data?.email)
      formik.setFieldValue("password", res?.data?.password)
      formik.setFieldValue("memo", res?.data?.memo)
      formik.setFieldValue("account_status", res?.data?.account_status)
      formik.setFieldValue(
        "company_type",
        company_type || res?.data?.company_type
      )
    },
  })

  const getErrorClass = (field, type = "input") => {
    if (formik.submitCount === 0) return ""
    if (formik.errors[field]) {
      switch (type) {
        case "input":
          return "has-error"
        case "container":
          return "has-error-container"
        case "nest":
          return "has-error-container-nest"
      }
    }
    return ""
  }

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])
  return (
    <>
      <BreadCrumb items={breadCrumbItems} />
      <PageCard title={t("Register company")} style={{ marginTop: "22px" }}>
        {isLoading || isFetching ? (
          <Skeleton />
        ) : (
          <StyledForm onSubmit={formik.handleSubmit} autoComplete={"off"}>
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Company name")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last gap-grid"}>
              <TextField
                name={"company_name"}
                height={"50px"}
                value={formik.values.company_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.company_name && formik.errors.company_name
                }
                showCounting
                className={getErrorClass("company_name", "input")}
              />

              <small>{t("(Within 100 char)")}</small>
            </Grid>
            <Grid className={"flex-break grid-gap"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Use of Service")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <RadioButtons
                options={service_options}
                value={formik.values.company_type}
                onChange={(evt) => {
                  formik.setFieldValue("company_type", evt.target.value)
                }}
                error={
                  formik.touched.company_type && formik.errors.company_type
                }
                disabled={id ? true : false}
                className={getErrorClass("company_type", "input")}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Representative name")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"representative"}
                value={formik.values.representative}
                onChange={formik.handleChange}
                showCounting
                maxLength={20}
                error={
                  formik.touched.representative && formik.errors.representative
                }
                className={getErrorClass("representative", "input")}
              />
              <small>{t("(Within 20 char)")}</small>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}> {t("Po. Box No.")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                height={"50px"}
                name={"po_box_no"}
                value={formik.values.po_box_no}
                onChange={formik.handleChange}
                error={formik.touched.po_box_no && formik.errors.po_box_no}
                maxLength={10}
                className={getErrorClass("po_box_no", "input")}
              />
              <small>{t("(Within 10 char)")}</small>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Address")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"address"}
                height={"50px"}
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && formik.errors.address}
                maxLength={100}
                className={getErrorClass("address", "input")}
              />
              <small>{t("(Within 100 char)")}</small>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Phone number")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"phone_number"}
                height={"50px"}
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                error={
                  formik.touched.phone_number && formik.errors.phone_number
                }
                maxLength={100}
                className={getErrorClass("phone_number", "input")}
              />
              <small>{t("(Within 100 char)")}</small>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("FAX number")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"fax_number"}
                value={formik.values.fax_number}
                onChange={formik.handleChange}
                error={formik.touched.fax_number && formik.errors.fax_number}
                maxLength={100}
                className={getErrorClass("address", "input")}
              />
              <small>{t("(Within 100 char)")}</small>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}> {t("Reciption hours")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                height={"50px"}
                name={"reception_hours"}
                value={formik.values.reception_hours}
                onChange={formik.handleChange}
                error={
                  formik.touched.reception_hours &&
                  formik.errors.reception_hours
                }
                maxLength={100}
                className={getErrorClass("reception_hours", "input")}
              />
              <small>{t("(Within 100 char)")}</small>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Email")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"email"}
                height={"50px"}
                value={formik.values.email}
                onChange={formik.handleChange}
                autoComplete={"off"}
                placeholder={"例：someone@example.com"}
                error={formik.touched.email && formik.errors.email}
                maxLength={100}
                className={getErrorClass("email", "input")}
              />
              <small>{t("(Within 100 char)")}</small>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}>{t("Password")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"password"}
                value={formik.values.password || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={"例：abcd0123"}
                width={"100%"}
                autoComplete={"off"}
                type={showPassword ? "text" : "password"}
                error={formik.touched.password && formik.errors.password}
                maxLength={16}
                className={getErrorClass("password", "input")}
              />
              <p className={"password-info-text"}>
                {t("Password must be 0 - 16 characters")}
              </p>
              <span
                onClick={() => {
                  setShowPassword((prev) => !prev)
                }}
                className={"password-show-hide"}
              >
                {formik.values.password.length ? (
                  !showPassword ? (
                    <EyeInvisibleOutlined className={"eye-icon"} />
                  ) : (
                    <EyeOutlined className={"eye-icon"} />
                  )
                ) : null}
              </span>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}> {t("Memo")}</div>
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <TextField
                name={"memo"}
                value={formik.values.memo}
                onChange={formik.handleChange}
                type={"textarea"}
                rows={9}
                maxLength={500}
                error={formik.touched.memo && formik.errors.memo}
                className={getErrorClass("memo", "input")}
              />
              <small>{t("(Within 500 char)")}</small>
            </Grid>
            <Grid className={"flex-break"} />
            <Grid className={"grid-header"} background>
              <div className={"label-wrapper"}>
                <div className={"title"}> {t("Usage status")}</div>
                <Required />
              </div>
            </Grid>
            <Grid className={"flex-full grid-row-last"}>
              <RadioButtons
                options={status_options}
                value={formik.values.account_status}
                onChange={(evt) => {
                  formik.setFieldValue("account_status", evt.target.value)
                }}
                error={
                  formik.touched.account_status && formik.errors.account_status
                }
                className={getErrorClass("account_status", "input")}
              />
            </Grid>
            <Grid className={"flex-break"} />
            <div className={"button-wrapper"}>
              <Button
                background={"#F3F3F3"}
                minheight={40}
                color={"#191919"}
                bordercolor={"#D2D1D1"}
                onClick={() => router.push("/company")}
                disabled={isCreating || isUpdating || isCreatingAdultCompany}
              >
                {t("Cancel")}
              </Button>
              <Button
                className={"register-button"}
                background={"#0782C8"}
                bordercolor={"#0782C8"}
                htmlType={"submit"}
                minheight={40}
                loading={isCreating || isUpdating || isCreatingAdultCompany}
              >
                {id ? t("Update") : t("Register")}
              </Button>
            </div>
          </StyledForm>
        )}
      </PageCard>
    </>
  )
}
export { CompanyForm }
